import { COOKIE_DETAILS, THEME_CHANGE_URL } from "../../config/default.config";
import { cookieUtils } from "../../utils/cookie.utils";
import { encryptUtils } from "../../utils/encryption.utils";
import { AppThunk } from "../index";
import { setLoading } from "./seqr-ops.slice";
import axios from "axios";

const setLoader = (loading: boolean): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(setLoading(loading));
  };
};

const SetThemes = async (
  isDark: any,
  setReady: (status: boolean, isDark: boolean) => void
) => {
  console.log("themeType111 ::", isDark);
  let cookiedata = cookieUtils.getCookie(COOKIE_DETAILS.COOKIE_NAME)!;
  let decryptedCookieData = encryptUtils.decryptURL(cookiedata);

  const decryptedSessionId = encryptUtils.decryptURL(decryptedCookieData.sessionId)
  console.log("decryptedCookieData :", decryptedCookieData.sessionId);

  try {
    const requestData = {
      sessionId:
        decryptedCookieData.sessionId,
        // "Gw8nRM7ysKnPuAmPkilOa/4WtSV1LSX9OkHUJE6252Gym1ehSR0P6hEHahVMLUaV",
      code: isDark ? "DARK" : "LIGHT",
    };

    const response = await axios.post(
      THEME_CHANGE_URL.THEME_CHANGE,
      requestData
    );

    if (response.status === 204) {
      setReady(true, isDark);
    }

    return response.data;
  } catch (error: any) {
    console.error("Error:", error);
    if (error.response) {
      console.error("Response data:", error.response.data);
      console.error("Response status:", error.response.status);
      console.error("Response headers:", error.response.headers);
    }
    return 0;
  }
};


export const seqrOpsActions = {
  setLoader,
  SetThemes
};
