import { useEffect, useState } from "react";
import {
  SESSION_KEY,
  KEYCLOAK_CLIENTS,
  COOKIE_DETAILS,
  ASSET_REDIRECT_SCOPES,
  SEQROPS_ANALYTICAL_DASHBOARD_GRAFANA_LOGIN_URL,
  SEQROPS_ANALYTICAL_DASHBOARD_IPAD_GRAFANA_LOGIN_URL,
} from "../../config/default.config";
import Navbar from "../navbar.component/navbar";
import { useAppSelector } from "../../store";
import {
  IRenderDashboard,
  IUserAuthorizations,
} from "../../store/keycloak/asset-keycloak.interface";
import { encryptUtils } from "../../utils/encryption.utils";
import { sessionStorageUtils } from "../../utils/session-storage.utils";
import { cookieUtils } from "../../utils/cookie.utils";

function SeqropsGrafanaComponent() {
  const authorozedScopes = useAppSelector(
    (state) => state.assetKeycloak.userAuthorizations
  );
  const themeFromStore = useAppSelector((state) => state.seqrOps.theme);
  const authToken = useAppSelector((state) => state.assetKeycloak.auth_token);
  const [loggedUser, setLoggedUser] = useState<string>();
  const [userScopesFromLocalStorage, setUserScopesFromLocalStorage] =
    useState<IUserAuthorizations>();
  const [theme, setTheme] = useState<string>("light");
  const [renderDashboard, setRenderDashboard] = useState<IRenderDashboard>({
    analytics_dashboard: false,
  });
  const [analyticalDashboardURL, setAnalyticalDashboardURL] =
    useState<string>("");
  const [token, setToken] = useState("");
  const [dashboard, setDashboard] = useState<any>("");

  useEffect(() => {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    let isIpad: boolean = false;
    if (`${windowWidth}` <= "1080") {
      isIpad = true;
    }

    const analyticalsDashboardUrl = isIpad
      ? SEQROPS_ANALYTICAL_DASHBOARD_IPAD_GRAFANA_LOGIN_URL
      : SEQROPS_ANALYTICAL_DASHBOARD_GRAFANA_LOGIN_URL;

    console.log("analyticalsDashboardUrl :::", analyticalsDashboardUrl);

    setAnalyticalDashboardURL(analyticalsDashboardUrl);

    if (
      sessionStorageUtils.getLocalStorage(SESSION_KEY.LOCAL_STORAGE_KEY) !==
      null
    ) {
      let tokenData: any = sessionStorageUtils.getLocalStorage(
        SESSION_KEY.LOCAL_STORAGE_KEY
      );
      const urlParams = new URLSearchParams(window.location.search);
      const dashboardType = urlParams.get("dashboardType");
      const dashboard = urlParams.get("dashboard");
      setDashboard(dashboard);
      if (tokenData !== null) {
        if (dashboardType !== null) {
          let renderDashboardData: IRenderDashboard = {
            analytics_dashboard: false,
          };
          if (dashboardType === ASSET_REDIRECT_SCOPES.ANALYTICS_DASHBOARD) {
            renderDashboardData.analytics_dashboard = true;
          }
          setRenderDashboard(renderDashboardData);
        }
        setLoggedUser(tokenData?.token?.username);
        setUserScopesFromLocalStorage(tokenData?.token?.userAuthorizations);
        setToken(tokenData?.token?.auth_token);
      }
    } else {
      setToken("");
    }
  }, [authorozedScopes]);

  useEffect(() => {
    if (token !== "" && token !== authToken && authToken !== "") {
      setToken(authToken);
    }
  }, [authToken]);

  useEffect(() => {
    if (themeFromStore) {
      setTheme("dark");
    } else if (!themeFromStore) {
      setTheme("light");
    }
    let cookieName = COOKIE_DETAILS.COOKIE_NAME;
    let encryptedCookieData = cookieUtils.getCookie(cookieName);
    let decryptedCookieData = encryptUtils.decryptURL(encryptedCookieData);
    console.log("decryptedCookieData ::: ", decryptedCookieData);
  }, [themeFromStore]);

  const renderCustomerLoads = () => {
    return (
      <iframe
        className="seqrops-frame-conworx"
        src={
          "https://dtech.grafana.seqrops.in/d/d29613a7-a6ba-4c8a-9e8d-1aec27c10cea/load-data?orgId=1&ckiosk&auth_token=" +
          token +
          "&theme=" +
          theme
        }
        title="seqrops-operational-dashboard"
        width="100%"
        height="100vh"
      ></iframe>
    );
  };

  const renderPeakLoads = () => {
    return (
      <iframe
        className="seqrops-frame-conworx"
        src={
          "https://dtech.grafana.seqrops.in/d/b04f738a-0338-41c1-b800-544c32202d63/peak-power?orgId=1&ckiosk&auth_token=" +
          token +
          "&theme=" +
          theme
        }
        title="seqrops-operational-dashboard"
        width="100%"
        height="100vh"
      ></iframe>
    );
  };

  const renderLoads = () => {
    return (
      <iframe
        className="seqrops-frame-conworx"
        src={
          "https://dtech.grafana.seqrops.in/d/be3b0ecb-1fa2-4c5d-8992-f743675de55f/loads?orgId=1&ckiosk&auth_token=" +
          token +
          "&theme=" +
          theme
        }
        title="seqrops-operational-dashboard"
        width="100%"
        height="100vh"
      ></iframe>
    );
  };
  useEffect(() => {}, []);
  return (
    <div>
      <div className="seqrops-navbar-section">
        <Navbar
          loggeduser={
            userScopesFromLocalStorage !== undefined ? loggedUser : "Admin"
          }
        />
      </div>
      {dashboard === "Loads"
        ? renderLoads()
        : dashboard === "PeakLoads"
        ? renderPeakLoads()
        : renderCustomerLoads()}
    </div>
  );
}

export default SeqropsGrafanaComponent;
