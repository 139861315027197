import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import "./dashboard.scss";
import { useAppDispatch, useAppSelector } from "../../store";
import {
  ASSET_REDIRECT_SCOPES,
  ASSET_SUITE_LOGIN_URL,
  ASSET_URLS,
  COOKIE_DETAILS,
  DASHBOARD_TYPE,
  IFRAME_ID,
  KEYCLOAK_CLIENTS,
  SESSION_KEY,
  SESSION_TYPE,
} from "../../config/default.config";
import assetModelsIcon from "../../assets/images/asset_model_icon.svg";
import analyticalsIcon from "../../assets/images/analytical-dashboard.svg";
import gridMonitoringIcon from "../../assets/images/grid-monitoring-icon.svg";
import assetManagementIcon from "../../assets/images/asset_management_icon.svg";
import adminConsoleIcon from "../../assets/images/device_management_icon.svg";
import managementDashboardIcon from "../../assets/images/management_dashboard_icon.svg";
import serverRoomMonitoringDashboard from "../../assets/images/Server-Room-Monitoring-Dashboard-icon.svg";
import operationalDashboardIcon from "../../assets/images/operational_dashboard.svg";
import fsmIcon from "../../assets/images/fsm_icon.svg";
import {
  IUserAuthorizations,
  IUserTokenDetails,
} from "../../store/keycloak/asset-keycloak.interface";
import Navbar from "../../components/navbar.component/navbar";
import { setTokens } from "../../store/keycloak/asset-keycloak.slice";
import { sessionStorageUtils } from "../../utils/session-storage.utils";
import { encryptUtils } from "../../utils/encryption.utils";
import { cookieUtils } from "../../utils/cookie.utils";
import settingsIcon from "../../assets/images/seqrops-settings_icon.svg";
import seqropsSiteManagement from "../../assets/images/site-management-icon.svg";

import { setTheme } from "../../store/seqr-ops/seqr-ops.slice";

function Dashboard() {
  const authorozedScopes = useAppSelector((state) => state.assetKeycloak);
  const dispatch = useAppDispatch();
  const [loggedUser, setLoggedUser] = useState<string>();
  const [userScopesFromLocalStorage, setUserScopesFromLocalStorage] =
    useState<IUserAuthorizations>();
  const [ready, setReady] = useState<boolean>(false);
  const [zoomedTile, setZoomedTile] = useState<string>("");
  const urlParams = new URLSearchParams(window.location.search);

  const handleMouseEnter = (tile: string) => {
    setZoomedTile(tile);
  };

  const handleMouseLeave = () => {
    setZoomedTile("");
  };

  // const handleClick = (e: any) => {
  //   // e.preventDefault();
  //   // dispatch(seqrOpsActions.setLoader(true));
  //   window.location.href = ASSET_URLS.ASSET_MODELS_URL;
  // };

  useEffect(() => {
    let cookieName = COOKIE_DETAILS.COOKIE_NAME;
    let sessionId = cookieUtils.getCookie(cookieName);

    let decryptedSessionThemeType = encryptUtils.decryptURL(sessionId);

    if (
      decryptedSessionThemeType?.sessionId == null ||
      decryptedSessionThemeType?.sessionId === undefined ||
      decryptedSessionThemeType?.sessionId === ""
    ) {
      localStorage.clear();
      window.location.replace(ASSET_SUITE_LOGIN_URL);
    } else {
      if (
        sessionStorageUtils.getLocalStorage(SESSION_KEY.LOCAL_STORAGE_KEY) !==
        null
      ) {
        let cookieName = COOKIE_DETAILS.COOKIE_NAME;
        let encryptedCookieData = cookieUtils.getCookie(cookieName);
        let decryptedCookieData = encryptUtils.decryptURL(encryptedCookieData);
        if (decryptedCookieData?.themeType === "LIGHT") {
          dispatch(setTheme(false));
        } else if (decryptedCookieData?.themeType === "DARK") {
          dispatch(setTheme(true));
        }

        let userTokenDetails: IUserTokenDetails =
          sessionStorageUtils.getLocalStorage(
            SESSION_KEY.LOCAL_STORAGE_KEY
          )?.token;
        if (userTokenDetails !== null) {
          setUserScopesFromLocalStorage(userTokenDetails?.userAuthorizations);
          setLoggedUser(userTokenDetails?.username);
          dispatch(setTokens(userTokenDetails));

          const newArray = [];
          let data: any = userTokenDetails?.userAuthorizations;
          const trueKeys = Object.keys(data).filter((key) => data[key]);
          trueKeys.forEach((key) => {
            newArray.push(key);
          });
          if (
            trueKeys.length === 5 &&
            trueKeys.includes(ASSET_REDIRECT_SCOPES.OPERATIONAL_DASHBOARD) &&
            urlParams.get(DASHBOARD_TYPE.REDIRECT) == null
          ) {
            let dashboardURL = ASSET_URLS.OPERATIONAL_DASHBOARD_URL;
            const queryParams = new URLSearchParams({
              dashboardType: ASSET_REDIRECT_SCOPES.OPERATIONAL_DASHBOARD,
            });
            const urlWithParams = `${dashboardURL}?${queryParams.toString()}`;
            window.location.href = urlWithParams;
          }

          setReady(true);
        }
      } else {
        window.location.href = ASSET_SUITE_LOGIN_URL;
      }
    }
  }, []);

  const renderLoads = () => {
    return (
      <div
        className={`seqrops-dashboard-tile-wrapper ${
          zoomedTile === "loads" ? "zoom-effect" : ""
        }`}
        onMouseEnter={() => handleMouseEnter("loads")}
        onMouseLeave={handleMouseLeave}
        onClick={() => {
          handleDashboardClick(
            ASSET_REDIRECT_SCOPES.ANALYTICS_DASHBOARD,
            "Loads"
          );
        }}
      >
        <div className="seqrops-dashboard-tile-middle-section1">
          <div className="seqrops-tile-inner-section">
            <img
              src={analyticalsIcon}
              className="img-fluid"
              alt="efficency-dsh-img"
            />
            <span>
              Consumer Load <br /> Profile
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderPeakLOads = () => {
    return (
      <div
        className={`seqrops-dashboard-tile-wrapper ${
          zoomedTile === "peakPower" ? "zoom-effect" : ""
        }`}
        onMouseEnter={() => handleMouseEnter("peakPower")}
        onMouseLeave={handleMouseLeave}
        onClick={() => {
          handleDashboardClick(
            ASSET_REDIRECT_SCOPES.ANALYTICS_DASHBOARD,
            "PeakLoads"
          );
        }}
      >
        <div className="seqrops-dashboard-tile-middle-section1">
          <div className="seqrops-tile-inner-section">
            <img
              src={analyticalsIcon}
              className="img-fluid"
              alt="efficency-dsh-img"
            />
            <span>
              Peak Consumption <br /> Analysis
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderCustomerLoads = () => {
    return (
      <div
        className={`seqrops-dashboard-tile-wrapper ${
          zoomedTile === "customerloads" ? "zoom-effect" : ""
        }`}
        onMouseEnter={() => handleMouseEnter("customerloads")}
        onMouseLeave={handleMouseLeave}
        onClick={() => {
          handleDashboardClick(
            ASSET_REDIRECT_SCOPES.ANALYTICS_DASHBOARD,
            "CustomerLoads"
          );
        }}
      >
        <div className="seqrops-dashboard-tile-middle-section1">
          <div className="seqrops-tile-inner-section">
            <img
              src={analyticalsIcon}
              className="img-fluid"
              alt="efficency-dsh-img"
            />
            <span>
              Consumer Load
              <br /> Comparison
            </span>
          </div>
        </div>
      </div>
    );
  };

  const [dashboardTypee, setDashboardTypee] = useState<string | null>(null);

  useEffect(() => {
    let userTokenDetails: IUserTokenDetails =
      sessionStorageUtils.getLocalStorage(SESSION_KEY.LOCAL_STORAGE_KEY)?.token;
    if (userTokenDetails !== null) {
      setUserScopesFromLocalStorage(userTokenDetails?.userAuthorizations);
      setLoggedUser(userTokenDetails?.username);
      dispatch(setTokens(userTokenDetails));
      const newArray = [];
      let data: any = userTokenDetails?.userAuthorizations;
      const trueKeys = Object.keys(data).filter((key) => data[key]);
      trueKeys.forEach((key) => {
        newArray.push(key);
      });
      const urlParams = new URLSearchParams(window.location.search);
      // const dashboardType = urlParams.get("redirectFrom");
      // setDashboardTypee(dashboardType);
    }
  }, [dashboardTypee]);

  const handleDashboardClick = (domain: string, dashboard: string) => {
    let dashboardURL = ASSET_URLS.ANALYTICS_DASHBOARD;

    const queryParams = new URLSearchParams({
      dashboardType: domain,
      dashboard: dashboard,
    });
    const urlWithParams = `${dashboardURL}?${queryParams.toString()}`;
    window.open(urlWithParams, "_self");
  };

  if (!ready) {
    return null;
  }

  return (
    <>
      {ready && (
        <>
          <div className="seqrops-dashboard-wrapper">
            <div className="seqrops-navbar-section">
              <Navbar
                loggeduser={
                  userScopesFromLocalStorage !== undefined
                    ? loggedUser
                    : authorozedScopes.username
                }
              />
            </div>
            <div className="container mt-5">
              <div className="tile-wrapper-main flex-wrap">
                <React.Fragment>
                  {renderCustomerLoads()}
                  {renderLoads()}
                  {renderPeakLOads()}
                </React.Fragment>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Dashboard;
