import { COOKIE_DETAILS } from "../config/default.config";

const setCookie = (name: any, value: any, options : {domain?: string} = {}) => {
    
    const { domain } = options

    let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);
    
    if (domain) {
      updatedCookie += "; domain=" + domain;
    }
    
    document.cookie = updatedCookie;
  }
  
  
const deleteCookie = (name: any) => {

  let domain = COOKIE_DETAILS.COOKIE_DOMAIN

  let deletedCookie = encodeURIComponent(name) + "=" + encodeURIComponent("")+ "; max-age=0; domain=" + domain + ";";

    document.cookie = deletedCookie;
  }

const getCookie = (name: any) => {
    let matches = document.cookie.match(new RegExp(
      "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }

  export const cookieUtils = {
    setCookie,
    deleteCookie,
    getCookie,
  };

  
  